import { Formik } from "formik";

interface AppFormProps {
  children: any;
  initialValues?: any;
  validationSchema?: any;
  handleSubmit?: Function;
  enableReinitialize?: boolean;
  hasValues?: boolean;
  formRef?: any;
  [x: string]: any;
}

export default function AppForm({
  initialValues,
  validationSchema,
  handleSubmit,
  children,
  enableReinitialize = false,
  hasValues = false,
  formRef,
  ...rest
}: AppFormProps) {
  
  const renderForm = (props: any) => {
    if (hasValues) {
      return children(props);
    }
    return children;
  };

  return (
    <Formik<any>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (handleSubmit) {
          handleSubmit({ formValues: values });
        }
      }}
      enableReinitialize={enableReinitialize}
      innerRef={formRef}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} {...rest}>
          {renderForm(props)}
        </form>
      )}
    </Formik>
  );
}
