import { Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { routeConfig } from "utils/route-constants";
import './navBar.css'
import { useEffect, useState } from "react";
import { api } from "api";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "features/events/eventSlice";

const userToken = window.localStorage.getItem("token");

export default function AppNavbar() {
  const [userEvent, setUserEvent] = useState<any>({})

  const dispatch = useDispatch<any>()
  const { events } = useSelector((store: any) => store.event)

  // // fetching the events and filtering out the logged in user's event
  // useEffect(() => {
  //   if(userToken) {
  //       dispatch(getEvents())
  //   }
  // }, [])

  useEffect(() => {
    if(userToken && (events.length > 0)) {
      setUserEvent(events.filter((event: any) => event.user === JSON.parse(window.localStorage.getItem('user') ?? '')?._id)[0])
    }
  }, [events])
  // // fetching the events and filtering out the logged in user's event
  // useEffect(() => {
  //   if(userToken && (events.length === 0)) {
  //     dispatch(getEvents())
  //   } else if (userToken && (events.length > 0)) {
  //     setUserEvent(events.filter((event: any) => event.user === JSON.parse(window.localStorage.getItem('user') ?? '')?._id)[0])
  //   }
  // }, [events])

  // removing the events drop down on outside click
  useEffect(() => {
    const hideEventsNav = (e: any) => {
      if(!e.path.includes(document.getElementById('eventsNavLink'))) {
        document.getElementById('eventsNavUl')?.classList.remove('eventsNavShow')
        document.getElementById('eventsNavLink')?.classList.remove('active')
      }
    }
    window.addEventListener('click', hideEventsNav)

    // cleanup to free memory and compute
    return () => window.removeEventListener('click', hideEventsNav)
  }, [])

  const handleLogout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const eventsNavBtnHandler = (e: any) => {
    // add the class of active on clicking the events link
    document.getElementById('eventsNavLink')?.classList.toggle('active')
    // adding the show class on the events nav ul
    document.getElementById('eventsNavUl')?.classList.toggle('eventsNavShow')
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#" as={Link} to="/">
          Instructor
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            /*style={{ maxHeight: "100px" }}*/
            navbarScroll
          >
            {userToken ? (
              <>
                <Nav.Link as={Link} to={routeConfig.editProfile}>
                  Profile
                </Nav.Link>
                <span id="eventsNavLink" className="navMyEventsWrapper" onClick={e => eventsNavBtnHandler(e)}>
                  Events
                  <ul id="eventsNavUl" className="navUl">
                    <li>
                      <Link to="/my-events" className="navUlLiA">
                        My Events
                      </Link>
                    </li>
                    <li>
                      <Link to="/new-event" className="navUlLiA">
                        New Event
                      </Link>
                    </li>
                    {
                      Object.keys(userEvent).length > 0 ? (
                        <li>
                          <Link to={`/booking/${userEvent._id}`} className="navUlLiA">
                            Book Event
                          </Link>
                        </li>
                      ) : ''
                    }
                  </ul>
                </span>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </>
            ) : (
              <Link to="/auth/login" className="navLoginButton">
                Login
              </Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
