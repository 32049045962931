import { AppNavbar } from "components/common";

export default function BaseLayout({ children }: any) {
  return (
    <>
      <AppNavbar />
      <div className="container mt-3">{children}</div>
    </>
  );
}
