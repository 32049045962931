import { array, number, object, SchemaOf, string } from "yup";

export interface ProfileValidationRules {
  gender?: string;
  drivingSchoolName?: string;
  contactNo?: string;
  email?: string;
  pricePerHour?: number;
  transmission?: string;
  serviceSuburbs?: string;
  firstPackageHour?: number;
  firstPackagePrice?: number;
  secondPackageHour?: number;
  secondPackagePrice?: number;
  thirdPackageHour?: string;
  thirdPackagePrice?: string;
  drivingSchoolPic?: string;
}

export const updateProfileSchema: SchemaOf<ProfileValidationRules> =
  object().shape({
    gender: string().notRequired().label("Gender"),
    drivingSchoolName: string().notRequired().label("Driving School Name"),
    contactNo: string().notRequired().label("Contact Number"),
    email: string().notRequired().label("Email"),
    pricePerHour: number().notRequired().label("Price Per Hour"),
    transmission: string().notRequired().label("Transmission"),
    serviceSuburbs: string().notRequired().label("Service Suburbs"),
    firstPackageHour: number().notRequired().label("First Package / Hr"),
    firstPackagePrice: number().notRequired().label("First Package / Hr Price"),
    secondPackageHour: number().notRequired().label("Second Package / Hr"),
    secondPackagePrice: number()
      .notRequired()
      .label("Second Package / Hr Price"),
    thirdPackageHour: string().notRequired().label("Third Package / Hr"),
    thirdPackagePrice: string().notRequired().label("Third Package / Hr Price"),
    drivingSchoolPic: string().notRequired(),
  });

export interface BookNowValidationRules {
  name: string;
  contactNo?: number;
  email: string;
  message: string;
}

export const bookNowSchema: SchemaOf<BookNowValidationRules> = object().shape({
  name: string().required().label("Name"),
  email: string().email().required().label("Email"),
  contactNo: number().required().label("Contact Number"),
  message: string().required().label("Message"),
});
