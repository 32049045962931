interface RouteParam {
  actual: string;
  getRoutePath(param: string): string;
}

interface RouteConfig {
  // auth
  login: string;

  // main
  setupClientAccount: string;
  editProfile: string;
  bookNow: RouteParam;
}

function getAuthRoutes() {
  const prefix = "/auth";

  return { login: prefix + "/login" };
}

function getMainRoutes() {
  return {
    setupClientAccount: "/setup-client-account",
    bookNow: {
      actual: "/book-now/:id",
      getRoutePath(param: string) {
        return "/book-now/" + param;
      },
    },
  };
}

function getProtectedRoutes() {
  return {
    editProfile: "/user/profile",
  };
}

export const routeConfig: RouteConfig = {
  ...getAuthRoutes(),
  ...getMainRoutes(),
  ...getProtectedRoutes(),
};
