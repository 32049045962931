

import ToastMessage, { showToastMessage } from 'components/ToastMessage'
import { deleteEvent, eventReset, getEvents } from 'features/events/eventSlice'
import BaseLayout from 'layouts/base'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import './myEvents.css'

const MyEvents = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { events, eventSuccess, eventError, eventMessage } = useSelector(store => store.event)

    // if not logged in, then redirect to home page
    useEffect(() => {
        if(!window.localStorage.getItem('token')) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        // fetch the events only if the user is logged in
        if(window.localStorage.getItem('token')) {
            dispatch(getEvents())
        }
    }, [eventSuccess])

    // show the toast message on error or success of event creation request
    useEffect(() => {
        if(eventSuccess) {
        showToastMessage(eventMessage, 'success')
        dispatch(eventReset())
        } else if(eventError) {
        showToastMessage(eventMessage, 'error')
        dispatch(eventReset())
        }
    }, [eventSuccess, eventError, eventMessage])

    const handleDeleteBtn = (event) => {
        dispatch(deleteEvent(event._id))
    }

    const handleEditBtn = (event) => {
        navigate(`/edit-event/${event._id}`)
    }

  return (
    <BaseLayout>
    <ToastMessage />
        <section className={`myEventsWrapper`}>
            <main className={`myEventsContainer`}>
                {
                    events.length === 0 ? (
                        'No events to show'
                    ) : (
                        events.map((event, index) => (
                            <div key={index} className={`myEventsEventWrapper`}>
                                <div style={{display:'flex', justifyContent:'space-between'}}>
                                    <h5 style={{width:'70%', marginBottom:'1rem'}}>{event.name}</h5>
                                    <div>
                                        <svg onClick={() => handleEditBtn(event)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#667172" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{cursor:'pointer', marginRight:'1rem'}} className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>

                                        <svg onClick={() => handleDeleteBtn(event)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#667172" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{cursor:'pointer'}} className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                    </div>
                                </div>
                                <p style={{marginBottom:'0.5rem'}}>{event.duration}</p>
                                <Link to={`/booking/${event._id}`} className={`myEventsLinks`} >
                                    View Booking Page
                                </Link>
                            </div>
                        ))
                    )
                }
            </main>
        </section>
    </BaseLayout>
  )
}

export default MyEvents