

import { useCallback, useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { Calendar, luxonLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import BaseLayout from 'layouts/base'
import Spinner from 'components/spinner/Spinner'
import './bookEvent.css'
import '../newEvent/newEvent.css'
import { useDispatch, useSelector } from 'react-redux'
import { bookEvent, eventReset, getEvent } from 'features/events/eventSlice'
import { useParams } from 'react-router-dom'
import ToastMessage, { showToastMessage } from 'components/ToastMessage'

const BookEvent = () => {
    const [date, setDate] = useState(new Date())
    const [view, setView] = useState('week')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [eventLocation, setEventLocation] = useState('')
    const [message, setMessage] = useState('')
    const [clickLocation, setClickLocation] = useState('')
    const [eventDetailsShow, setEventDetailsShow] = useState({})
    const [invalidTime, setInvalidTime] = useState(false)
    const [meetingDate, setMeetingDate] = useState(DateTime.now())
    const [meetingTimeArr, setMeetingTimeArr] = useState([])
    const [meetingTime, setMeetingTime] = useState({
        'from': '09:00am',
        'to': '05:00pm'
    })
    const [calendarEvents, setCalendarEvents] = useState([])

    const clickRef = useRef(null)

    const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 })

    const params = useParams()
    const dispatch = useDispatch()
    const { event, eventError, eventSuccess, eventMessage, eventLoading } = useSelector(store => store.event)

    // fetch the event details
    useEffect(() => {
        if(params.eventId) {
            dispatch(getEvent(params.eventId))
        }
    }, [params.eventId])

    // if event is fetched, then populate the booked events data
    useEffect(() => {
        if(Object.keys(event).length > 0) {
            // making a deep copy so as to modify it
            let bookingDetailsCopy = JSON.parse(JSON.stringify(event.bookingDetails))

            // converting the dates into correct format
            const formattedBookingDetails = bookingDetailsCopy.map(booking => {
                booking.start = DateTime.fromISO(booking.start).toJSDate()
                booking.end = DateTime.fromISO(booking.end).toJSDate()
                return booking
            })
            setCalendarEvents(formattedBookingDetails)
        }
    }, [event])

    // if event is not fetched/failed, then show the toast message and do not load the calendar
    useEffect(() => {
        if(eventError) {
            showToastMessage(eventMessage, 'error')
            dispatch(eventReset())
        } else if(eventSuccess) {
            showToastMessage(eventMessage, 'success')
            dispatch(eventReset())
        }
    }, [eventError, eventMessage, eventSuccess])


    // clean up on unmounting to prevent any memory leak issues
    useEffect(() => {
        return () => {
            window.clearTimeout(clickRef?.current)
        }
    }, [])

    // removing the list of timings on outside click
    useEffect(() => {
        const meetingTimeWrapperEl = document.querySelectorAll('.meetingTimeWrapper')

        // remove the show class from UL element on outside click
        const hideUl = e => {
        meetingTimeWrapperEl.forEach(el => {
            if(!e.composedPath().includes(el)) {
            el.lastElementChild.classList.remove('selectOptionUlShow')
            }
        })
        }

        window.addEventListener('click', hideUl)

        return () => window.removeEventListener('click', hideUl)
    }, [eventLoading])

    // generate the list to select the 'from' and 'to' time
    useEffect(() => {
        const generateMeetingTime = () => {
        let meetingTime = []
        for(let i = 0; i < 96; i++) {
            meetingTime.push(DateTime.now().startOf('day').plus({minute: i*15}))
        }
        setMeetingTimeArr(meetingTime)
        }
        generateMeetingTime()
    }, [])

    // check if the time selected by the client is invalid or not
    useEffect(() => {
        if(Object.keys(event).length > 0) {
            const userSelectedMeetTime = JSON.parse(event.meetingTime)[meetingDate.weekdayShort.toUpperCase()]

            // if the day selected by the user is unavailable, then don't check for the validity
            if(userSelectedMeetTime !== 'Unavailable') {
                const userSelectedHourVal = +userSelectedMeetTime.from.split(':')[0] + ((userSelectedMeetTime.from.slice(-2).toLowerCase() === 'pm') && (userSelectedMeetTime.from.split(':')[0] !== '12') ? 12 : 0)
                const userSelectedEndHourVal = +userSelectedMeetTime.to.split(':')[0] + ((userSelectedMeetTime.to.slice(-2).toLowerCase() === 'pm') && (userSelectedMeetTime.to.split(':')[0] !== '12') ? 12 : 0)
                const userSelectedMinuteVal = +userSelectedMeetTime.from.split(':')[1].slice(0, 2)
                const userSelectedEndMinuteVal = +userSelectedMeetTime.to.split(':')[1].slice(0, 2)
        
                
                const clientSelectedHourVal = +meetingTime.from.split(':')[0] + ((meetingTime.from.slice(-2).toLowerCase() === 'pm') && (meetingTime.from.split(':')[0] !== '12') ? 12 : 0)
                const clientSelectedEndHourVal = (+meetingTime.to.split(':')[0] + ((meetingTime.to.slice(-2).toLowerCase() === 'pm') && (meetingTime.to.split(':')[0] !== '12') ? 12 : 0))
                const clientSelectedMinuteVal = +meetingTime.from.split(':')[1].slice(0, 2)
                const clientSelectedEndMinuteVal = +meetingTime.to.split(':')[1].slice(0, 2)
                
                // checking the hour validity
                if( clientSelectedHourVal > clientSelectedEndHourVal ) {
                    setInvalidTime(true)
                    // checking minutes validity, if hour value is the same
                } else if(
                    (meetingTime.from.split(':')[0] === meetingTime.to.split(':')[0]) &&
                    (clientSelectedMinuteVal > clientSelectedEndMinuteVal)
                ) {
                    setInvalidTime(true)
                } else if(
                    (DateTime.now().set({hour: userSelectedHourVal, minute: userSelectedMinuteVal}) > DateTime.now().set({hour: clientSelectedHourVal, minute: clientSelectedMinuteVal})) || (DateTime.now().set({hour: userSelectedEndHourVal, minute: userSelectedEndMinuteVal}) < DateTime.now().set({hour: clientSelectedEndHourVal, minute: clientSelectedEndMinuteVal}))
                ) {
                    setInvalidTime(true)
                } else {
                    setInvalidTime(false)
                }
            }
        }
    }, [meetingTime])

    // store the coordinates of the mouse click to be used to show the event details box
    useEffect(() => {
        const storeBoundingClientRect = e => {
            setClickLocation(e.target.getBoundingClientRect())
            // hide the event details card on outside click 
            if(!e.path.includes(document.getElementById('displayEventWrapper'))) {
                document.getElementById('displayEventWrapper')?.classList?.remove('show')
            }
        }
        window.addEventListener('click', storeBoundingClientRect)

        // clean up, to free up the memory and cpu
        return () => {
            window.removeEventListener('click', storeBoundingClientRect)
        }
    }, [])

    // show list to select the event timings
    const meetingTimeHandler = e => {
        e.currentTarget.lastElementChild.classList.toggle('selectOptionUlShow')
    }

    // slot selection/click handler for calendar
    const onSelectSlotHandler = useCallback((slotInfo) => {
        // we are using setTimeout so that if a person clicks on an event two times, then our pop up should not show two times
        window.clearTimeout(clickRef?.current)
        clickRef.current = window.setTimeout(() => {
            const bookEventWrapper = document.getElementById('bookEventWrapper')
            const calendarWrapper = document.getElementById('calendarWrapper')
            
            // if there is already some bookings for this day, then dont show the book event form
            const dayEvents = calendarEvents.filter(event => (event.start >= slotInfo.start) && (event.end <= slotInfo.end))
            if(dayEvents.length > 0) {
                bookEventWrapper.classList.remove('show')
                bookEventWrapper.style.top = '0px'
                bookEventWrapper.style.left = '0px'
                return
            }


            // if user has selected the day as unavailable, then dont show the book event popup form
            if(JSON.parse(event.meetingTime)[DateTime.fromJSDate(slotInfo.start).weekdayShort.toUpperCase()] === 'Unavailable') {
                // removing popup form if it is showing
                bookEventWrapper.classList.remove('show')
                bookEventWrapper.style.top = '0px'
                bookEventWrapper.style.left = '0px'
                return
                // if the date is earlier than today, then dont show the booking popup form
            } else if(DateTime.now() > DateTime.fromJSDate(slotInfo.start)) {
                // removing popup form if it is showing
                bookEventWrapper.classList.remove('show')
                bookEventWrapper.style.top = '0px'
                bookEventWrapper.style.left = '0px'
                return
            }

            // meeting time for the clicked day
            const meetingTimingsRange = JSON.parse(event.meetingTime)[DateTime.fromJSDate(slotInfo.start).weekdayShort.toUpperCase()]
            // extracting start and end hour and minute value
            const meetingTimeStartHour = +meetingTimingsRange.from.split(':')[0] + ((meetingTimingsRange.from.slice(-2).toLowerCase() === 'pm') && (meetingTimingsRange.from.split(':')[0] !== '12') ? 12 : 0)
            const meetingTimeStartMinute = +meetingTimingsRange.from.split(':')[1].slice(0,2)
            const meetingTimeEndHour = +meetingTimingsRange.to.split(':')[0] + ((meetingTimingsRange.to.slice(-2).toLowerCase() === 'pm') && (meetingTimingsRange.to.split(':')[0] !== '12') ? 12 : 0)
            const meetingTimeEndMinute = +meetingTimingsRange.to.split(':')[1].slice(0,2)

            // checking if the slot start time is less than the user selected start time
            if(DateTime.fromJSDate(slotInfo.start).set({hour: meetingTimeStartHour, minute: meetingTimeStartMinute}) > DateTime.fromJSDate(slotInfo.start)) {
                bookEventWrapper.classList.remove('show')
                bookEventWrapper.style.top = '0px'
                bookEventWrapper.style.left = '0px'
                return
            // checking if the slot end time is more than the user selected end time
            } else if(DateTime.fromJSDate(slotInfo.start).set({hour: meetingTimeEndHour, minute: meetingTimeEndMinute}) < DateTime.fromJSDate(slotInfo.end)) {
                bookEventWrapper.classList.remove('show')
                bookEventWrapper.style.top = '0px'
                bookEventWrapper.style.left = '0px'
                return
            }

            // do not show the booking form if clicked just below the date 
            if((DateTime.fromJSDate(new Date(slotInfo.start)).minute === DateTime.fromJSDate(new Date(slotInfo.end)).minute) && (DateTime.fromJSDate(new Date(slotInfo.start)).hour === DateTime.fromJSDate(new Date(slotInfo.end)).hour)) {
                return
            }
            
            // if the book event form is visible, then remove it, otherwise show it
            if(bookEventWrapper.classList.contains('show')) {
                bookEventWrapper.classList.remove('show')
                bookEventWrapper.style.top = '0px'
                bookEventWrapper.style.left = '0px'
                return
            } else {
                bookEventWrapper.classList.add('show')
            }

            setMeetingDate(DateTime.fromJSDate(new Date(slotInfo.start)))
            // setting meeting time according to the selected slot
            setMeetingTime(() => {
                const start = DateTime.fromJSDate(new Date(slotInfo.start))
                const end = DateTime.fromJSDate(new Date(slotInfo.end))

                return { 'from': start.toLocaleString(DateTime.TIME_SIMPLE), 'to': end.toLocaleString(DateTime.TIME_SIMPLE) }
            })

            

            // check if the action is selection or click, and then set the position of the booking form wrapper accordingly
            if(slotInfo.action === 'select') {
                bookEventWrapper.style.top = slotInfo.bounds?.top + 'px'

                // pre-calculating the right of the booking form to avoid overflowing
                const preCalcRight = slotInfo.bounds?.left + bookEventWrapper.getBoundingClientRect().width

                // if the booking form is overflowing, then adjust the left of the wrapper
                if(calendarWrapper.getBoundingClientRect().right < preCalcRight) {
                    bookEventWrapper.style.left = (slotInfo.bounds?.left - (preCalcRight - calendarWrapper.getBoundingClientRect().right)) + 'px'
                } else {
                    bookEventWrapper.style.left = slotInfo.bounds?.left + 'px'
                }
            } else {
                bookEventWrapper.style.top = slotInfo.box?.clientY + 'px'

                // pre-calculating the right of the booking form to avoid overflowing
                const preCalcRight = slotInfo.box?.clientX + bookEventWrapper.getBoundingClientRect().width

                // if the booking form is overflowing, then adjust the left of the wrapper
                if(calendarWrapper.getBoundingClientRect().right < preCalcRight) {
                    bookEventWrapper.style.left = (slotInfo.box?.clientX - (preCalcRight - calendarWrapper.getBoundingClientRect().right)) + 'px'
                } else {
                    bookEventWrapper.style.left = slotInfo.box?.clientX + 'px'
                }
            }
        }, 250)
    })

    // submit button handler
    const submitHandler = e => {
        // if the time selected is not valid, then do not submit the form
        if(invalidTime) {
            showToastMessage('Please select a valid time', 'error')
            return
        } else if(contactNumber.trim() === '') {
            showToastMessage('Contact number cannot be empty', 'error')
            return
        } else if(eventLocation.trim() === '') {
            showToastMessage('Location cannot be empty', 'error')
            return
        } else if(email.trim() === '') {
            showToastMessage('Email address cannot be empty', 'error')
            return
        } else if(name.trim() === '') {
            showToastMessage('Name cannot be empty', 'error')
            return
        }

        // create calendar event
        const clientSelectedHourVal = +meetingTime.from.split(':')[0] + ((meetingTime.from.slice(-2).toLowerCase() === 'pm') && (meetingTime.from.split(':')[0] !== '12') ? 12 : 0)
        const clientSelectedEndHourVal = (+meetingTime.to.split(':')[0] + ((meetingTime.to.slice(-2).toLowerCase() === 'pm') && (meetingTime.to.split(':')[0] !== '12') ? 12 : 0))
        const clientSelectedMinuteVal = +meetingTime.from.split(':')[1].slice(0, 2)
        const clientSelectedEndMinuteVal = +meetingTime.to.split(':')[1].slice(0, 2)
        const calendarEvent = {
            title: name,
            start: meetingDate.set({hour: clientSelectedHourVal, minute: clientSelectedMinuteVal}).toJSDate(),
            end: meetingDate.set({hour: clientSelectedEndHourVal, minute: clientSelectedEndMinuteVal}).toJSDate(),
            contactNumber,
            eventLocation,
            message,
            email,
            name
        }

        setCalendarEvents(prev => [...prev, calendarEvent])
        // hiding the pop-up form
        document.getElementById('bookEventWrapper').classList.remove('show')
        // resetting the form inputs
        setContactNumber('')
        setEmail('')
        setName('')
        setEventLocation('')
        setMessage('')

        dispatch(bookEvent({eventId: event._id, bookingDetails: [...calendarEvents, calendarEvent]}))
    }

    // cancel button handler
    const cancelHandler = e => {
        // hiding the pop-up form
        document.getElementById('bookEventWrapper').classList.remove('show')
        // resetting the form inputs
        setContactNumber('')
        setEmail('')
        setName('')
        setEventLocation('')
        setMessage('')
    }

    if(eventLoading) {
        return <Spinner />
    }

    // color the time slots which are not selected by the user
    const disableInvalidTime = date => {
        // color the past date with gray color
        if(DateTime.fromJSDate(date) < DateTime.now()) {
            return {
                style: {
                    backgroundColor:'#eee'
                }
            }
        }

        const meetingTimeObj = JSON.parse(event.meetingTime)
        // loop through each day and set the styles accordingly
        for(let day of Object.keys(meetingTimeObj)) {
            // if the meeting time for this day is unavailable, then disable the complete day
            if((DateTime.fromJSDate(date).weekdayShort.toUpperCase() === day) && (meetingTimeObj[day] === 'Unavailable')) {
                return {
                    style: {
                        backgroundColor:'#eee'
                    }
                }
            } else if(meetingTimeObj[day] === 'Unavailable') {
                continue
            }

            const meetingTimeHour = +meetingTimeObj[day]?.from.split(':')[0] + ((meetingTimeObj[day]?.from.slice(-2).toLowerCase() === 'pm') && (meetingTimeObj[day]?.from.split(':')[0] !== '12') ? 12 : 0)
            const meetingTimeMinute = +meetingTimeObj[day]?.from.split(':')[1].slice(0, 2)
            const meetingTimeEndHour = +meetingTimeObj[day]?.to.split(':')[0] + ((meetingTimeObj[day]?.to.slice(-2).toLowerCase() === 'pm') && (meetingTimeObj[day]?.to.split(':')[0] !== '12') ? 12 : 0)
            const meetingTimeEndMinute = +meetingTimeObj[day]?.to.split(':')[1].slice(0, 2)

            // if time is less than the minimum time
            if((DateTime.fromJSDate(date).weekdayShort.toUpperCase() === day) && (DateTime.fromJSDate(date) < DateTime.fromJSDate(date).set({hour: meetingTimeHour, minute: meetingTimeMinute}))) {
                return {
                    style: {
                        backgroundColor: '#eee'
                    }
                }
                // if time is more than the maximum time
            } else if((DateTime.fromJSDate(date).weekdayShort.toUpperCase() === day) && (DateTime.fromJSDate(date) >= DateTime.fromJSDate(date).set({hour: meetingTimeEndHour, minute: meetingTimeEndMinute}))) {
                return {
                    style: {
                        backgroundColor: '#eee'
                    }
                }
            }
        }
        // Object.keys(meetingTimeObj).forEach(day => {
        //     const meetingTimeHour = +meetingTimeObj[day].from.split(':')[0] + ((meetingTimeObj[day].from.slice(-2).toLowerCase() === 'pm') && (meetingTime.from.split(':')[0] !== '12') ? 12 : 0)
        //     const meetingTimeMinute = +meetingTimeObj[day].from.split(':')[1].slice(0, 2)

        //     if((DateTime.fromJSDate(date).weekdayShort.toUpperCase() === day) && (DateTime.fromJSDate(date).hour < meetingTimeHour)) {
        //         return {
        //             style: {
        //                 backgroundColor: '#eee'
        //             }
        //         }
        //     }
        // })

    }

    // show the booking details on clicking on the appointment
    const bookingClickHandler = bookingData => {
        window.clearTimeout(clickRef?.current)

        clickRef.current = window.setTimeout(() => {
            // check if the user is logged in and it is the same user who created this event
            if(JSON.parse(window.localStorage.getItem('user'))?._id === event.user) {
                // set event details to show in the event details card
                setEventDetailsShow(bookingData)

                const displayPopup = document.getElementById('displayEventWrapper')
                // hide the book event form popup, if it is showing
                document.getElementById('bookEventWrapper').classList.remove('show')
                const calendarWrapper = document.getElementById('calendarWrapper')

                // setting the position of the popup
                displayPopup.style.top = (clickLocation.bottom - calendarWrapper.scrollTop + document.documentElement.scrollTop) + 20 + 'px'
                displayPopup.style.left = clickLocation.left + 'px'

                // show the event details card
                displayPopup.classList.add('show')

                // calculating the right edge of the display popup card to avoid overflowing the viewport
                const preCalcRight = displayPopup.getBoundingClientRect().width + clickLocation.left

                // if the popup card is overflowing, then adjust its position to fit it in the viewport
                if(preCalcRight > calendarWrapper.getBoundingClientRect().right) {
                    displayPopup.style.left = (clickLocation.left - (preCalcRight - calendarWrapper.getBoundingClientRect().right)) + 'px'
                }

            } else {
                // hide the book event popup form if it is visible
                document.getElementById('bookEventWrapper').classList.remove('show')
            }
        }, 250)
    }


  return (
    <BaseLayout>
        <ToastMessage />
        {
            (Object.keys(event).length > 0) ? (
                <>
                    <h3 style={{textAlign:'center', margin:'2rem 0'}}>
                        {event.name}
                    </h3>
                    <div id="calendarWrapper" style={{height:'80vh', margin:'2rem 0'}}>
                        <Calendar
                        localizer={localizer}
                        date={date}
                        onNavigate={(newDate) => setDate(newDate)}
                        views={['week']}
                        view={view}
                        onView={(newView) => setView(newView)}
                        selectable={true}
                        onSelectSlot={onSelectSlotHandler}
                        step={60}
                        timeslots={1}
                        events={calendarEvents}
                        slotPropGetter={disableInvalidTime}
                        onDoubleClickEvent={bookingClickHandler}
                        />
                    </div>
            
                    {/* pop-up event creation form */}
                    <div id="bookEventWrapper" className='bookEventPopupFormWrapper'>
                        <form onSubmit={e => e.preventDefault()}>
                            <input className='bookEventPopupFormInput' type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" required />
                            <input className='bookEventPopupFormInput' type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email address" required />
                            <input className='bookEventPopupFormInput' type="number" value={contactNumber} onChange={e => setContactNumber(e.target.value)} placeholder="Contact no" />
                            <div style={{marginBottom:'0.5rem', position:'relative'}}>
                                <span style={{marginRight:'0.5rem', marginLeft:'0.5rem'}}>
                                    {meetingDate.weekdayShort + ', ' + meetingDate.monthShort + ' ' + meetingDate.day}
                                </span>
            
                                {/* from time selector */}
                                <span onClick={meetingTimeHandler} className={`meetingTimeWrapper bookEventPopupDateTimeSelector`}>
                                    {meetingTime.from}
            
                                    <ul style={{width:'150%'}} className={`newEventMeetingTimeUl`}>
                                        {
                                        meetingTimeArr.map((time, index) => (
                                            <li onClick={(e) => {
                                            e.target.parentElement.childNodes.forEach(el => el.classList.remove('newEventMeetingTimeLiActive'))
            
                                            e.target.classList.add('newEventMeetingTimeLiActive')
            
                                            return setMeetingTime((prev) => ({...prev, 'from': `${(time.hour - 12) > 0 ? (String(time.hour - 12).length === 1 ? (`0${time.hour - 12}`) : (time.hour - 12)) : (time.hour.toString().length === 1 ? (`0${time.hour}`) : (`${time.hour}`))}:${String(time.minute).length === 1 ? (`0${time.minute}`) : (time.minute)} ${(time.hour-12) >= 0 ? 'PM' : 'AM'}`}))
                                            }} className={`newEventMeetingTimeLi`} key={index}>
                                            {
                                            (time.hour - 12) > 0 ? 
                                            (String(time.hour - 12).length === 1 ? '0'+(time.hour - 12) : (time.hour - 12)) :
                                            (time.hour.toString().length === 1 ? (
                                                `0${time.hour}`
                                            ) :
                                            (`${time.hour}`))
                                            }:{time.minute.toString().length === 1 ? (
                                                `0${time.minute}`
                                            ) :
                                            (`${time.minute}`)}{(time.hour - 12) >= 0 ? 'pm' : 'am'}
                                            </li>
                                        ))
                                        }
                                    </ul>
                                </span>
                                -
                                {/* to time selector */}
                                <span onClick={meetingTimeHandler} className={`meetingTimeWrapper bookEventPopupDateTimeSelector`}>
                                    {meetingTime.to}
            
                                    <ul style={{width:'150%'}} className={`newEventMeetingTimeUl`}>
                                        {
                                        meetingTimeArr.map((time, index) => (
                                            <li onClick={(e) => {
                                            e.target.parentElement.childNodes.forEach(el => el.classList.remove('newEventMeetingTimeLiActive'))
            
                                            e.target.classList.add('newEventMeetingTimeLiActive')
            
                                            return setMeetingTime((prev) => ({...prev, 'to': `${(time.hour - 12) > 0 ? (String(time.hour - 12).length === 1 ? (`0${time.hour - 12}`) : (time.hour - 12)) : (time.hour.toString().length === 1 ? (`0${time.hour}`) : (`${time.hour}`))}:${String(time.minute).length === 1 ? (`0${time.minute}`) : (time.minute)} ${(time.hour-12) >= 0 ? 'PM' : 'AM'}`}))
                                            }} className={`newEventMeetingTimeLi`} key={index}>    
                                            {
                                            (time.hour - 12) > 0 ? 
                                            (String(time.hour - 12).length === 1 ? '0'+(time.hour - 12) : (time.hour - 12)) :
                                            (time.hour.toString().length === 1 ? (
                                                `0${time.hour}`
                                            ) :
                                            (`${time.hour}`))
                                            }:{time.minute.toString().length === 1 ? (
                                                `0${time.minute}`
                                            ) :
                                            (`${time.minute}`)}{(time.hour - 12) >= 0 ? 'pm' : 'am'}
                                            </li>
                                        ))
                                        }
                                    </ul>
                                </span>

                                {/* error message */}
                                <span style={{position:'absolute', bottom:'-0.8rem', left:'55%', fontSize:'0.7rem', color:'red', display:invalidTime ? `block` : 'none' }}>
                                    Invalid time
                                </span>
                            </div>
            
                            <input style={{marginBottom:'1rem'}} className='bookEventPopupFormInput' type="text" value={eventLocation} onChange={e => setEventLocation(e.target.value)} placeholder="Add location"/>
            
                            <textarea className='bookEventPopupTextarea' value={message} onChange={e => setMessage(e.target.value)} placeholder='Message' />
            
                            <div style={{display:'flex', gap:'0.7rem'}}>
                                <input onClick={e => submitHandler(e)} className='bookEventPopupFormBtn' type="submit" value="Submit" />
                                <span onClick={e => cancelHandler(e)} className='bookEventPopupFormBtn'>
                                    Cancel
                                </span>
                            </div>
                        </form>
                    </div>

                    {/* pop-up event display card */}
                    <div id="displayEventWrapper" className='bookEventPopupFormWrapper' style={{padding:'1.5rem'}}>
                        <div>
                            <div className='displayEventPopupField'>{eventDetailsShow.name}</div>
                            <div className='displayEventPopupField'>{eventDetailsShow.email}</div>
                            <div className='displayEventPopupField'>
                                <a href={`tel:${eventDetailsShow.contactNumber}`}>
                                    {eventDetailsShow.contactNumber}
                                </a>
                            </div>
                            <div className='displayEventPopupField'>
                                <span style={{marginRight:'1rem'}}>
                                    {DateTime.fromJSDate(new Date(eventDetailsShow.start)).toLocaleString({ weekday: 'short', month: 'short', day: '2-digit' })}
                                </span>
            
                                {/* from time selector */}
                                <span>
                                    {DateTime.fromJSDate(new Date(eventDetailsShow.start)).toLocaleString({ hour: '2-digit', minute: '2-digit' })}
                                </span>
                                &nbsp;-&nbsp;
                                {/* to time selector */}
                                <span>
                                    {DateTime.fromJSDate(new Date(eventDetailsShow.end)).toLocaleString({ hour: '2-digit', minute: '2-digit' })}
                                </span>
                            </div>
            
                            <div className='displayEventPopupField'>{eventDetailsShow.eventLocation}</div>
            
                            <div>{eventDetailsShow.message}</div>
                        </div>
                    </div>
                </>
            ) : (
                <div style={{textAlign:'center', marginTop:'1rem'}}>
                    Event could not be fetched. Please try refreshing the page.
                </div>
            )
        }
    </BaseLayout>
  )
}

export default BookEvent