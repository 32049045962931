import React from "react";
import { ErrorMessage } from "formik";

interface FieldErrorProps {
  field?: string;
  onlyFieldError?: boolean;
  hasCustomMessage?: boolean;
  message?: string;
  [x: string]: any;
}

interface MessageObj {
  [x: string]: any;
}

type MessageType = string | string[] | MessageObj[];

export default function FieldError({
  field,
  onlyFieldError = false,
  hasCustomMessage = false,
  message = "",
  ...props
}: FieldErrorProps) {
  const getSingleMessage = (msg: string) => {
    return (
      <span
        style={{
          display: "inline-block",
          color: "tomato",
          marginBottom: "0.7rem",
        }}
      >
        {msg}
      </span>
    );
  };

  const renderMessage = (msg: MessageType) => {
    if (typeof msg === "string") {
      return getSingleMessage(msg);
    }

    if (onlyFieldError) {
      return <></>;
    }

    return msg.map((singleMsg, idx) => {
      let message = "";

      if (typeof singleMsg === "string") {
        message = singleMsg;
      } else {
        const objectKey: string = Object.keys(singleMsg)[0];
        message = singleMsg[objectKey];
      }

      return (
        <React.Fragment key={idx.toString()}>
          {getSingleMessage(message)}
        </React.Fragment>
      );
    });
  };

  if (hasCustomMessage) {
    return getSingleMessage(message);
  }

  return (
    <ErrorMessage name={field ?? ""} {...props}>
      {(msg: MessageType) => renderMessage(msg)}
    </ErrorMessage>
  );
}
