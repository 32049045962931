import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// import { Card, ListGroup } from "react-bootstrap";
import { routeConfig } from "utils/route-constants";
import BaseLayout from "layouts/base";
import { useSearchContext } from "context/search-context";
// import { Typeahead } from "react-bootstrap-typeahead";
import { api } from "api";
import './mainHome.css'
import { useDispatch, useSelector } from "react-redux";
import { getAllEvents } from "features/events/eventSlice";

export default function HomeScreen() {
  const [suburbsLoading, setSuburbsLoading] = useState(false);
  const [suburbs, setSuburbs] = useState([]);
  const [suburb, setSuburb] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState<any>([]);
  const searchCtx = useSearchContext();

  const dispatch = useDispatch<any>()

  // get all the events
  useEffect(() => {
    dispatch(getAllEvents())
  }, [])

  useEffect(() => {
    setIsLoading(true);

    api.post("/filter/suburbs", { suburb: searchCtx.searchVal }).then((res) => {
      // console.log("filter_suburbs: ", res.data.suburbs);
      setProfiles(res.data.suburbs);
      setIsLoading(false);
    });

    setSuburbsLoading(true);
    api.get("/suburbs").then((res) => {
      const suburbs = res.data.suburbs.map((data: any) => data.locality);
      setSuburbs(suburbs);
      setSuburbsLoading(false);
    });
  }, [searchCtx.searchVal]);

  return (
    <BaseLayout>
      {/* <div className="row">
        <div className="col-md-10">
          {suburbsLoading && <div className="spinner-border" />}
          {!suburbsLoading && (
            <Typeahead
              id="suburb"
              placeholder="Search Suburbs ...."
              options={suburbs}
              defaultInputValue={searchCtx.searchVal}
              onChange={(selected: any) => setSuburb(selected[0])}
            />
          )}
        </div>
        <div className="col-md-2">
          <button
            className="btn btn-success btn-md"
            onClick={() => searchCtx.onChangeSearch(suburb)}
          >
            Search
          </button>
        </div>
      </div> */}
      <div className="d-flex justify-content-center align-items-end mt-3">
        <p style={{ fontWeight: "bold", fontSize: "22px" }}>Search Results:</p>
        <p style={{ fontSize: "22px", marginLeft: "10px" }}>
          {searchCtx.searchVal}
        </p>
      </div>
      <hr />

      {/* auto/manual tab */}
      <div style={{display: 'flex', justifyContent:'center', marginBottom: '1rem', marginTop:'2rem'}}>
        <span onClick={e => console.log('tab clicked')} style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} className="mainHomeTab">
          Auto
        </span>
        <span onClick={e => console.log('tab clicked')} style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }} className="mainHomeTab">
          Manual
        </span>
      </div>

      <div className="my-5">
        <div>
          {isLoading && <div className="spinner-border" />}
          {!isLoading && (
            <>
              {profiles.map((profile: any, idx: any) => (
                <div className="my-5">
                  <SearchResultItem key={idx} profile={profile} />
                </div>
              ))}
              {profiles.length === 0 && (
                <p className="alert alert-info">No results found ...</p>
              )}
            </>
          )}
        </div>
      </div>
    </BaseLayout>
  );
}

function SearchResultItem({ profile }: any) {
  // const [isOpen, setIsOpen] = useState(false);
  const [hours, setHours] = useState<any>()
  const [pricePerHour, setPricePerHour] = useState<any>()
  const navigate = useNavigate();
  
  const { allEvents } = useSelector((store: any): any => store.event)

  const userEvent = allEvents.filter((event: any) => (event.user === profile._id))

  // show/hide the packages section
  const handleCardClick = (e: any, profile: any) => {
    // if the click is not on the input element in the card, then toggle the show class
    if(!e.target.classList.contains('dynamicPriceInput')) {
      e.currentTarget.lastElementChild.classList.toggle('show')
    }
  }

  const calculatePricePerHour = (e: any) => {
    setHours(+e.target.value)

    if(+e.target.value <= 5) {
      setPricePerHour(Math.round(+e.target.value * profile.pricePerHour))
      return
    } else if(+e.target.value > 5 && +e.target.value < 10) {
      // calculating the discount on hourly rate
      const discount = profile.pricePerHour * 0.06

      // calculating new per hour price after accounting for the discount
      const pricePerHour = profile.pricePerHour - discount

      // calculating the final price according to the new hourly rate
      setPricePerHour(Math.round(+e.target.value * pricePerHour))
      return
    } else if(+e.target.value >= 10) {
      // calculating the discount on hourly rate
      const discount = profile.pricePerHour * 0.12

      // calculating new per hour price after accounting for the discount
      const pricePerHour = profile.pricePerHour - discount

      // calculating the final price according to the new hourly rate
      setPricePerHour(Math.round(+e.target.value * pricePerHour))
      return
    }
  }

  return (
    <div className="outerContainer">
      <div onClick={e => handleCardClick(e, profile)} className="cardWrapper">

        {/* user details section */}
        <div className="cardWrapperUserDetails">
          {/* user Avatar image */}
          <div className="cardAvatarWrapper">
            <img className="cardAvatarImg" referrerPolicy="no-referrer" src={profile.drivingSchoolPic || "/noAvatar.png"} alt="avatar" />
          </div>

          <div className="leftSection">
            <span className="cardDetailSpan">
              <span className="cardDetailsLabel">
                Name: &nbsp;
              </span>
              <span className="cardDetailsData">
                {profile.name}
              </span>
            </span>
            <span className="cardDetailSpan">
              <span className="cardDetailsLabel">
                Driving School Name: &nbsp;
              </span>
              <span className="cardDetailsData">
                {profile.drivingSchoolName}
              </span>
            </span>
            <span className="cardDetailSpan">
              <span className="cardDetailsLabel">
                Contact Number: &nbsp;
              </span>
              <span className="cardDetailsData">
                {profile.contactNo}
              </span>
            </span>
            <span className="cardDetailSpan">
              <span className="cardDetailsLabel">
                Gender: &nbsp;
              </span>
              <span className="cardDetailsData">
                {profile.gender}
              </span>
            </span>
            <span className="cardDetailSpan">
              <span className="cardDetailsLabel">
                Languages: &nbsp;
              </span>
              <span className="cardDetailsData">
                {profile.languages.join(', ')}
              </span>
            </span>
          </div>

          <div className="rightSection">
            
            <span className="cardDetailSpan">
              
              <span className="cardDetailsData cardDetailsPrice">
                <span style={{fontSize:'0.8rem', verticalAlign:'text-top'}}>$</span>{profile.pricePerHour}<span style={{fontSize:'0.7rem'}}>/hr</span>
              </span>
            </span>

          </div>

          {/* down arrow for mobile screens */}
          <div className="downArrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#aaa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
          </div>
        </div>

        {/* packages section */}
        <div style={{borderTop:'1px solid #ddd', paddingTop:'0.5rem', display:'none', overflow:'hidden'}}>
          <h2 style={{textAlign:'center', marginTop:'0.5rem'}}>Packages</h2>
          {/* first package */}
          <div className="cardDetailsPackagesContainer">
            <span>
              <span className="cardDetailsLabel">
                Hours: &nbsp;
              </span>
              <span className="cardDetailsData">
                {profile.firstPackageHour}
              </span>
            </span>

            {/* price */}
            <span className="cardDetailsData cardDetailsPackagePrice">
              ${profile.firstPackagePrice}
            </span>
          </div>

          {/* second package */}
          <div className="cardDetailsPackagesContainer">
            <span>
              <span className="cardDetailsLabel">
                Hours: &nbsp;
              </span>
              <span className="cardDetailsData">
                {profile.secondPackageHour}
              </span>
            </span>

            {/* price */}
            <span className="cardDetailsData cardDetailsPackagePrice">
              ${profile.secondPackagePrice}
            </span>
          </div>

          {/* third package */}
          <div className="cardDetailsPackagesContainer">
            <span>
              {/* <span className="cardDetailsLabel">
                Hours: &nbsp;
              </span> */}
              <span className="cardDetailsData">
                {profile.testPackage}
              </span>
            </span>

            {/* price */}
            <span className="cardDetailsData cardDetailsPackagePrice">
              ${profile.testPackagePrice}
            </span>
          </div>

          {/* dynamic price input */}
          <div className="cardDetailsPackagesContainer">
            <span>
              <input className="dynamicPriceInput" type="number" value={hours} onChange={e => calculatePricePerHour(e)} placeholder="Enter hours" />
            </span>

            <span className="cardDetailsData cardDetailsPackagePrice">
              {
                pricePerHour ? '$' + pricePerHour : 'NaN'
              }
            </span>
          </div>

          {/* action buttons */}
          <div style={{display:'flex', gap:'1rem', alignItems:'center', marginTop: '0.5rem', marginBottom: '0.5rem'}}>
            <a href={`tel:${profile.contactNo}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
            </a>

            <a href={`https://api.whatsapp.com/send?phone=${profile.contactNo}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
            </a>

            <span
              className="cardDetailsBookNowBtn"
              onClick={() =>
                navigate(routeConfig.bookNow.getRoutePath(profile._id))
              }
            >
              Book Now
            </span>
            {
              (userEvent.length > 0) && (
                <span
                  className="cardDetailsBookNowBtn"
                  onClick={() =>
                    navigate(`/booking/${userEvent[0]._id}`)
                  }
                >
                  Book Appointment
                </span>
              )
            }
          </div>
        </div>
      </div>
    </div>
    // <Card>
    //   <Card.Header>
    //     <b>Name</b>: {profile.name}
    //   </Card.Header>
    //   <ListGroup>
    //     <ListGroup.Item>
    //       <b>Gender</b>: {profile.gender}
    //     </ListGroup.Item>
    //     <ListGroup.Item>
    //       <b>Driving School Name</b>: {profile.drivingSchoolName}
    //     </ListGroup.Item>
    //     <ListGroup.Item>
    //       <b>Email</b>: {profile.email}
    //     </ListGroup.Item>
    //     <ListGroup.Item>
    //       <b>Contact Number</b>: {profile.contactNo}
    //     </ListGroup.Item>
    //     <ListGroup.Item>
    //       <b>Hourly Rate</b>: ${profile.pricePerHour}
    //     </ListGroup.Item>
    //   </ListGroup>
    //   <Card.Body>
    //     <h4
    //       className="text-center pointer"
    //       onClick={() => setIsOpen((prev) => !prev)}
    //     >
    //       Packages
    //     </h4>
    //     {isOpen && <hr />}
    //     {isOpen && (
    //       <Card>
    //         <ListGroup>
    //           <ListGroup.Item>
    //             <b>Hours</b>: {profile.firstPackageHour}
    //           </ListGroup.Item>
    //           <ListGroup.Item>
    //             <b>Price</b>: ${profile.firstPackagePrice}
    //           </ListGroup.Item>
    //         </ListGroup>
    //         <ListGroup>
    //           <ListGroup.Item>
    //             <b>Hours</b>: {profile.secondPackageHour}
    //           </ListGroup.Item>
    //           <ListGroup.Item>
    //             <b>Price</b>: ${profile.secondPackagePrice}
    //           </ListGroup.Item>
    //         </ListGroup>
    //         <ListGroup>
    //           <ListGroup.Item>
    //             <b>Hours</b>: {profile.thirdPackageHour}
    //           </ListGroup.Item>
    //           <ListGroup.Item>
    //             <b>Price</b>: ${profile.thirdPackagePrice}
    //           </ListGroup.Item>
    //         </ListGroup>
    //       </Card>
    //     )}
    //   </Card.Body>
    //   <Card.Footer>
    //     <div>
    //       <a href={`tel:${profile.contactNo}`}>
    //         <i className="fa fa-phone pointer search-icon" />
    //       </a>
    //       <a href={`https://api.whatsapp.com/send?phone=${profile.contactNo}`}>
    //         <i className="fa fa-whatsapp mx-3 pointer search-icon" />
    //       </a>
    //       <span
    //         className="pointer"
    //         onClick={() =>
    //           navigate(routeConfig.bookNow.getRoutePath(profile._id))
    //         }
    //       >
    //         Book Now
    //       </span>
    //     </div>
    //   </Card.Footer>
    // </Card>
  );
}
