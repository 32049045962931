// import { GoogleLogin } from '@react-oauth/google'
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useEffect, useState } from "react";
import { useSearchContext } from "context/search-context";
import { useNavigate } from "react-router";
// import { routeConfig } from "utils/route-constants";
import { api } from "api";
import './entry.css'


const userToken = window.localStorage.getItem("token");

export default function EntryScreen() {
  // const [isLoading, setIsLoading] = useState(false);
  const [suburbsLoading, setSuburbsLoading] = useState(false);
  const [suburbs, setSuburbs] = useState([]);
  const searchCtx = useSearchContext();
  const [suburb, setSuburb] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setSuburbsLoading(true);
    api.get("/suburbs").then((res) => {
      const suburbs = res.data.suburbs.map((data: any) => {
        return data.locality + ", " + data.state + " " + data.postcode;
      });
      setSuburbs(suburbs);
      setSuburbsLoading(false);
    });
  }, []);

  // const handleSuccess = async (res: any) => {
  //   console.log("res: ", res);
  //   setIsLoading(true);

  //   try {
  //     const response = await api.post("/google-login", {
  //       tokenId: res.credential,
  //     });
  //     const payload = response.data;
  //     const { token, user } = payload;
  //     window.localStorage.setItem("token", token);
  //     window.localStorage.setItem("user", JSON.stringify(user));
  //     window.location.href = "/user/profile";
  //   } catch (err) {
  //     console.log("error while login: ", err);
  //     setIsLoading(false);
  //   }
  // };

  // const handleFailure = (res: any) => {
  //   console.log("login_failed: ", res);
  // };

  const handleSelected = (selected: any) => {
    const suburb = selected[0];
    setSuburb(suburb);
  };

  const handleClick = () => {
    searchCtx.onChangeSearch(suburb);
    navigate("/search");
  };

  // <GoogleLogin onSuccess={handleSuccess} onError={() => handleFailure} />
  return (
    
    < 
    >
    
    
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="row mainWrapper" style={{ /*width: "30%"*/ marginBottom:'2rem' }}>
          <div className="col-md-12">
            {!userToken && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "15px",
                }}
              >
               <h3 style={{fontWeight: '600', textAlign:'center'}}>Find a car driving instructor</h3>
              </div>
            )}
               {/* <h3>Find a car driving instructor</h3> */}
               {/* <br></br> */}
              <div style={{paddingLeft:'1rem', paddingRight: '1rem'}} className="form-group formGroup">
              {suburbsLoading && <div className="spinner-border" />}
              {!suburbsLoading && (
                <>
                  <Typeahead
                    id="suburb"
                    placeholder="Enter your suburbs or postcode"
                    onChange={handleSelected}
                    options={suburbs}
                    style={{marginBottom:'1rem'}}
                    className='rounded-circle'
                  />
                  
                  <div
                    className="btn btn-success btn-sm mt-2 d-flex justify-content-center"
                    style={{ display: "flex", justifyContent: "center", fontWeight: '500', letterSpacing:'0.1rem', borderRadius:'50px', padding:'0.5rem', marginLeft:'2px', marginRight:'2px', width:'50%', margin:'auto' }}
                    onClick={handleClick}
                  >                   
                    Search
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// {
//   "error": "idpiframe_initialization_failed",
//   "details": "Not a valid origin for the client: http://localhost:3000 has not been registered for client ID 655899655458-doq3ancmg50jb51m409qplicio6ted4a.apps.googleusercontent.com. 
//   Please go to https://console.developers.google.com/ and register this origin for your project's client ID."
// }