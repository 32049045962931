import { useEffect, useState } from "react"
import { DateTime } from 'luxon'
import BaseLayout from "layouts/base"
import './newEvent.css'
import { useDispatch, useSelector } from "react-redux"
import { createEvent, eventReset } from "features/events/eventSlice"
import ToastMessage, { showToastMessage } from "components/ToastMessage"
import { useNavigate } from "react-router"

const NewEvent = () => {
  const [eventName, setEventName] = useState('')
  const [meetingTimeArr, setMeetingTimeArr] = useState([])
  const [meetingTime, setMeetingTime] = useState({
    'SUN': 'Unavailable',
    'MON': {
      'from': '09:00am',
      'to': '05:00pm'
    },
    'TUE': {
      'from': '09:00am',
      'to': '05:00pm'
    },
    'WED': {
      'from': '09:00am',
      'to': '05:00pm'
    },
    'THU': {
      'from': '09:00am',
      'to': '05:00pm'
    },
    'FRI': {
      'from': '09:00am',
      'to': '05:00pm'
    },
    'SAT': 'Unavailable'
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { eventLoading, eventSuccess, eventError, eventMessage } = useSelector(store => store.event)

  // if not logged in, then redirect to home page
  useEffect(() => {
    if(!window.localStorage.getItem('token')) {
        navigate('/')
    }
  }, [])

  // removing the list of timings on outside click
  useEffect(() => {
    const meetingTimeWrapperEl = document.querySelectorAll('.meetingTimeWrapper')

    // remove the show class from UL element on outside click
    const hideUl = e => {
      meetingTimeWrapperEl.forEach(el => {
        if(!e.composedPath().includes(el)) {
          el.lastElementChild.classList.remove('selectOptionUlShow')
        }
      })
    }

    window.addEventListener('click', hideUl)

    return () => window.removeEventListener('click', hideUl)
  }, [meetingTime])

  // generate the list to select the 'from' and 'to' time
  useEffect(() => {
    const generateMeetingTime = () => {
      let meetingTime = []
      for(let i = 0; i < 96; i++) {
        meetingTime.push(DateTime.now().startOf('day').plus({minute: i*15}))
      }
      setMeetingTimeArr(meetingTime)
    }
    generateMeetingTime()
  }, [])

  // show the toast message on error or success of event creation request
  useEffect(() => {
    if(eventSuccess) {
      showToastMessage(eventMessage, 'success')
      setEventName('')
      dispatch(eventReset())
    } else if(eventError) {
      showToastMessage(eventMessage, 'error')
      dispatch(eventReset())
    }
  }, [eventSuccess, eventError, eventMessage])

  const addButtonHandler = (e) => {
    // set the default 'from' and 'to' time
    const dayName = e.currentTarget.parentElement.firstElementChild.innerText
    setMeetingTime(prevMeetingTime => ({...prevMeetingTime, [dayName]: {
      'from': '09:00am',
      'to': '05:00pm'
    }}))
  }

  const meetingTimeHandler = e => {
    e.currentTarget.lastElementChild.classList.toggle('selectOptionUlShow')
  }

  const deleteButtonHandler = (e) => {
    // set the time to 'Unavailable'
    const dayName = e.currentTarget.parentElement.firstElementChild.innerText
    setMeetingTime(prevMeetingTime => ({...prevMeetingTime, [dayName]: 'Unavailable'}))
  }

  const submitHandler = (e) => {
    e.preventDefault()

    // show the error message when the event name is not there
    if(eventName.trim() === '') {
      showToastMessage('Event name cannot be empty', 'error')
      return
    }

    dispatch(createEvent({ name: eventName, meetingTime: JSON.stringify(meetingTime) }))
  }

  return (
    <BaseLayout>
    <ToastMessage />
      <div className={`newEventWrapper`}>
        <div className={`newEventContainer`}>
          <h2 style={{textAlign:'center', marginBottom:'2rem', fontWeight:'600'}}>Create New Event</h2>
          <form onSubmit={submitHandler}>
            <input className={`newEventFormInput`} value={eventName} onChange={e => setEventName(e.target.value)} placeholder="Event Name" />

            {/* select options drop down for meeting time */}
            <div className={`newEventMeetingTimeWrapper`}>
              <h4 className={`meetingTimeHeading`}>Set your weekly hours</h4>
              
              {
                // one block for each day of the week
                Object.keys(meetingTime).map((day, idx) => (
                  <div key={idx} className={`newEventMeetingTimeDayWrapper`}>
                    <span className={`newEventMeetingTimeDay`}>{day}</span>

                    {
                      meetingTime[day] === `Unavailable` ? (
                        <>
                          <span style={{textAlign:'center', flex:'1'}}>Unavailable</span>
                          {/* add button */}
                          <div className={`meetingTimeAddBtnWrapper`} onClick={addButtonHandler}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#667172" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                          </div>
                        </>
                        
                        ) : (
                        <>
                          {/* 'from' time selector */}
                          <div onClick={meetingTimeHandler} className={`meetingTimeWrapper newEventMeetingTime`}>
                            <span>{meetingTime[day].from}</span>
      
                            <ul className={`newEventMeetingTimeUl`}>
                              {
                                meetingTimeArr.map((time, index) => (
                                  <li onClick={(e) => {
                                    e.target.parentElement.childNodes.forEach(el => el.classList.remove('newEventMeetingTimeLiActive'))

                                    e.target.classList.add('newEventMeetingTimeLiActive')

                                    return setMeetingTime((prev) => ({...prev, [day]: {...prev[day], 'from': `${(time.hour - 12) > 0 ? (String(time.hour - 12).length === 1 ? (`0${time.hour - 12}`) : (time.hour - 12)) : (time.hour.toString().length === 1 ? (`0${time.hour}`) : (`${time.hour}`))}:${String(time.minute).length === 1 ? (`0${time.minute}`) : (time.minute)}${(time.hour-12) >= 0 ? 'pm' : 'am'}`}}))
                                  }} className={`newEventMeetingTimeLi`} key={index}>
                                    {
                                    (time.hour - 12) > 0 ? 
                                    (String(time.hour - 12).length === 1 ? '0'+(time.hour - 12) : (time.hour - 12)) :
                                    (time.hour.toString().length === 1 ? (
                                      `0${time.hour}`
                                    ) :
                                    (`${time.hour}`))
                                    }:{time.minute.toString().length === 1 ? (
                                      `0${time.minute}`
                                    ) :
                                    (`${time.minute}`)}{(time.hour - 12) >= 0 ? 'pm' : 'am'}
                                  </li>
                                ))
                              }
                            </ul>
                          </div>
      
                          <span className={`meetingTimeHyphen`}>-</span>
      
                          {/* 'to' time selector */}
                          <div onClick={meetingTimeHandler} style={{marginRight:'1rem !important'}} className={`meetingTimeWrapper newEventMeetingTime`}>
                            <span>{meetingTime[day].to}</span>
      
                            <ul className={`newEventMeetingTimeUl`}>
                              {
                                meetingTimeArr.map((time, index) => (
                                  <li onClick={(e) => {
                                    e.target.parentElement.childNodes.forEach(el => el.classList.remove('newEventMeetingTimeLiActive'))

                                    e.target.classList.add('newEventMeetingTimeLiActive')

                                    return setMeetingTime((prev) => ({...prev, [day]: {...prev[day], 'to': `${(time.hour - 12) > 0 ? (String(time.hour - 12).length === 1 ? (`0${time.hour - 12}`) : (time.hour - 12)) : (time.hour.toString().length === 1 ? (`0${time.hour}`) : (`${time.hour}`))}:${String(time.minute).length === 1 ? (`0${time.minute}`) : (time.minute)}${(time.hour-12) >= 0 ? 'pm' : 'am'}`}}))
                                  }} className={`newEventMeetingTimeLi`} key={index}>    
                                    {
                                    (time.hour - 12) > 0 ? 
                                    (String(time.hour - 12).length === 1 ? '0'+(time.hour - 12) : (time.hour - 12)) :
                                    (time.hour.toString().length === 1 ? (
                                      `0${time.hour}`
                                    ) :
                                    (`${time.hour}`))
                                    }:{time.minute.toString().length === 1 ? (
                                      `0${time.minute}`
                                    ) :
                                    (`${time.minute}`)}{(time.hour - 12) >= 0 ? 'pm' : 'am'}
                                  </li>
                                ))
                              }
                            </ul>
                          </div>
      
                          {/* delete button */}
                          <div className={`meetingTimeDeleteBtnWrapper`} onClick={deleteButtonHandler}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#667172" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                          </div>
      
                          {/* meeting time error */}
                          <span className={`text-small3 meetingTimeError ${
                            DateTime.now().set({hour: (meetingTime[day].from.split(':')[1].slice(-2) === 'pm' ? (meetingTime[day].from.split(':')[0] === '12' ? meetingTime[day].from.split(':')[0] : +meetingTime[day].from.split(':')[0] + 12) : (meetingTime[day].from.split(':')[0]))}).set({minute: meetingTime[day].from.split(':')[1].slice(0,2)}) > DateTime.now().set({hour: (meetingTime[day].to.split(':')[1].slice(-2) === 'pm' ? (meetingTime[day].to.split(':')[0] === '12' ? meetingTime[day].to.split(':')[0] : +meetingTime[day].to.split(':')[0] + 12) : (meetingTime[day].to.split(':')[0]))}).set({minute: meetingTime[day].to.split(':')[1].slice(0,2)}) ? ('show') : ('')
                          }`}>
                            Choose an end time later than start time
                          </span>
                        </>
                      )
                    }
                  </div>
                ))
              }
            </div>

            {/* <input id="flashBtn" className={`eventSubmitBtn`} type="submit" value="Create Event"/> */}
            <input id="flashBtn" className={`eventSubmitBtn`} type="submit" value={eventLoading ? 'Loading...' : "Create Event"} disabled={eventLoading ? true : false}/>
          </form>
        </div>
      </div>
    </BaseLayout>
  )
}

export default NewEvent