import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
    events: [],
    allEvents: [],
    event: {},
    eventLoading: false,
    eventSuccess: false,
    eventError: false,
    eventMessage: ''
}


export const createEvent = createAsyncThunk('event/createEvent', async (eventData, thunkAPI) => {
    try {
        const eventResponse = await api.post(`/events`, eventData, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": window.localStorage.getItem("token") ?? "" 
            }
        })

        const data = await eventResponse.data

        if(eventResponse) {
            return data
        } else {
            throw data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})


export const getEvents = createAsyncThunk('event/getEvents', async (_, thunkAPI) => {
    try {
        const eventResponse = await api.get('/events', {
            headers: {
                "Authorization": window.localStorage.getItem("token") ?? "" 
            }
        })

        const data = await eventResponse.data

        if(eventResponse) {
            return data
        } else {
            throw data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})

export const getAllEvents = createAsyncThunk('event/getAllEvents', async (_, thunkAPI) => {
    try {
        const eventResponse = await api.get('/events/all')

        const data = await eventResponse.data

        if(eventResponse) {
            return data
        } else {
            throw data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})


export const getEvent = createAsyncThunk('event/getEvent', async (eventId, thunkAPI) => {
    try {
        const eventResponse = await api.get(`/events/${eventId}`)

        const data = await eventResponse.data

        if(eventResponse) {
            return data
        } else {
            throw data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})


export const deleteEvent = createAsyncThunk('event/deleteEvent', async (eventId, thunkAPI) => {
    try {
        const eventResponse = await api.delete(`/events/${eventId}`, {
            headers: {
                "Authorization": window.localStorage.getItem("token") ?? "" 
            }
        })

        const data = await eventResponse.data
        
        if(eventResponse) {
            return data
        } else {
            throw data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})


export const updateEvent = createAsyncThunk('event/updateEvent', async (eventData, thunkAPI) => {
    try {
        const eventResponse = await api.put(`/events/${eventData.eventId}`, {
            name: eventData.name,
            meetingTime: eventData.meetingTime
        }, 
        {
            headers: {
                'Content-type': 'application/json',
                "Authorization": window.localStorage.getItem("token") ?? "" 
            }
        })

        const data = await eventResponse.data

        if(eventResponse) {
            return data
        } else {
            throw data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})


export const bookEvent = createAsyncThunk('event/bookEvent', async (eventData, thunkAPI) => {
    try {
        const eventResponse = await api.put(`/events/${eventData.eventId}/book`, {
            bookingDetails: eventData.bookingDetails
        }, 
        {
            headers: {
                'Content-type': 'application/json',
                "Authorization": window.localStorage.getItem("token") ?? "" 
            }
        })

        const data = await eventResponse.data

        if(eventResponse) {
            return data
        } else {
            throw data
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
    }
})

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        eventReset: (state) => {
            state.eventError = false
            state.eventLoading = false
            state.eventMessage = ''
            state.eventSuccess = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createEvent.pending, state => {
            state.eventLoading = true
        }).addCase(createEvent.fulfilled, (state, action) => {
            state.eventLoading = false
            state.eventSuccess = true
            state.eventMessage = action.payload.message
        }).addCase(createEvent.rejected, (state, action) => {
            state.eventLoading = false
            state.eventError = true
            state.eventMessage = action.payload
        }).addCase(getEvents.pending, state => {
            state.eventLoading = true
        }).addCase(getEvents.fulfilled, (state, action) => {
            state.eventLoading = false
            state.events = action.payload.events
        }).addCase(getEvents.rejected, (state, action) => {
            state.eventLoading = false
            state.eventError = true
            state.eventMessage = action.payload
        }).addCase(deleteEvent.pending, state => {
            state.eventLoading = true
        }).addCase(deleteEvent.fulfilled, (state, action) => {
            state.eventLoading = false
            state.eventSuccess = true
            state.eventMessage = action.payload.message
            state.event = {}
        }).addCase(deleteEvent.rejected, (state, action) => {
            state.eventLoading = false
            state.eventError = true
            state.eventMessage = action.payload
        }).addCase(getEvent.pending, state => {
            state.eventLoading = true
        }).addCase(getEvent.fulfilled, (state, action) => {
            state.eventLoading = false
            state.event = action.payload.event
        }).addCase(getEvent.rejected, (state, action) => {
            state.eventLoading = false
            state.eventError = true
            state.eventMessage = action.payload
        }).addCase(updateEvent.pending, state => {
            state.eventLoading = true
        }).addCase(updateEvent.fulfilled, (state, action) => {
            state.eventLoading = false
            state.eventSuccess = true
            state.eventMessage = action.payload.message
        }).addCase(updateEvent.rejected, (state, action) => {
            state.eventLoading = false
            state.eventError = true
            state.eventMessage = action.payload
        }).addCase(bookEvent.pending, state => {
            state.eventLoading = true
        }).addCase(bookEvent.fulfilled, (state, action) => {
            state.eventLoading = false
            state.eventSuccess = true
            state.eventMessage = action.payload.message
        }).addCase(bookEvent.rejected, (state, action) => {
            state.eventLoading = false
            state.eventError = true
            state.eventMessage = action.payload
        }).addCase(getAllEvents.pending, state => {
            state.eventLoading = true
        }).addCase(getAllEvents.fulfilled, (state, action) => {
            state.eventLoading = false
            state.allEvents = action.payload.allEvents
        }).addCase(getAllEvents.rejected, (state, action) => {
            state.eventLoading = false
            state.eventError = true
            state.eventMessage = action.payload
        })
    }
})

export const { eventReset } = eventSlice.actions

export default eventSlice.reducer