import axios from "axios";

let baseURL = "http://localhost:8000/api";
if (process.env.NODE_ENV === "production") {
  baseURL =  "/api";
  // baseURL = "https://lcardrive.com.au/api"; - For seperate Frontend deployemnt
}

const api = axios.create({
  baseURL,
});

export { api };

 export const GCid='655899655458-2tsfcqbb7tclq9tj3s0jorc8b4gqm7bv.apps.googleusercontent.com'
// export const GCid='1058597103322-nht55471oskk212ldjuhb6gv4hq61dc4.apps.googleusercontent.com'
