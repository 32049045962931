import { useRoutes } from "react-router";
import { authRoutes } from "./auth-route";
import { mainRoutes } from "./main-routes";
import { protectedRoutes } from "./protected-route";

export default function AppRoutes() {
  const routing = useRoutes([mainRoutes, authRoutes, protectedRoutes]);

  return <>{routing}</>;
}
