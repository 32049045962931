import './spinner.css'

function Spinner() {
    return (
      <div className={`loadingSpinnerContainer`}>
        <div className={`loadingSpinner`}></div>
      </div>
    )
  }
  
  export default Spinner