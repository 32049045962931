import { GoogleOAuthProvider } from "@react-oauth/google";
import { GCid } from "api";
import { SearchContextProvider } from "context/search-context";
import { Route, Routes } from "react-router";
import NewEvent from "screens/newEvent/newEvent";
import MyEvents from "screens/myEvents/myEvents";
import AppRoutes from "./routes";
import EditEvent from "screens/editEvent/editEvent";
import BookEvent from "screens/bookEvent/bookEvent";

function App() {
  return (
    <>
    <GoogleOAuthProvider clientId={GCid}>
      <SearchContextProvider>
        <AppRoutes />
        <Routes>
          <Route path='/new-event' element={<NewEvent />} />
          <Route path='/my-events' element={<MyEvents />} />
          <Route path='/edit-event/:id' element={<EditEvent />} />
          <Route path='/booking/:eventId' element={<BookEvent />} />
        </Routes>
      </SearchContextProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
