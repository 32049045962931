import React, { useContext, useState } from "react";

interface SearchType {
  searchVal: string;
  onChangeSearch(params: string): void;
}

const SearchContext = React.createContext<SearchType>({
  searchVal: "",
  onChangeSearch: (params: string) => {},
});

export function SearchContextProvider({ children }: any) {
  const [searchVal, setSearchVal] = useState("");

  const handleSearch = (val: string) => {
    setSearchVal(val);
  };

  return (
    <SearchContext.Provider value={{ searchVal, onChangeSearch: handleSearch }}>
      {children}
    </SearchContext.Provider>
  );
}

export const useSearchContext = () => useContext(SearchContext);
