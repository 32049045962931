import { GoogleLogin } from "@react-oauth/google";
import { api } from "api";


export default function LoginScreen() {
  // const handleSuccess = (res: any) => {
  //   console.log("login_success: ", res);
  // };

  const handleSuccess = async (res: any) => {
    console.log("res: ", res);
    // setIsLoading(true);

    try {
      const response = await api.post("/google-login", {
        tokenId: res.credential,
      });
      const payload = response.data;
      const { token, user } = payload;
      window.localStorage.setItem("token", token);
      window.localStorage.setItem("user", JSON.stringify(user));
      window.location.href = "/user/profile";
    } catch (err) {
      console.log("error while login: ", err);
      // setIsLoading(false);
    }
  };


  // const handleFailure = (res: any) => {
  //   console.log("login_failed: ", res);
  // };

  return (
    <div className="container-fluid bg-light text-dark py-4">
      <div className="row justify-content-center align-items-center">
        <h2 className="text-center">Login</h2>
      </div>
      <hr />
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ height: "50vh" }}
      >
      
        <GoogleLogin 
          onSuccess={handleSuccess}
           onError={() => {
           console.log('Login Failed')
         }}
       />
      </div>
    </div>
  );
}
