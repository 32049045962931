import { Outlet, RouteObject } from "react-router";
import { LoginScreen } from "../screens/auth";

export const authRoutes: RouteObject = {
  path: "auth",
  element: <Outlet />,
  children: [
    {
      path: "login",
      element: <LoginScreen />,
    },
  ],
};
