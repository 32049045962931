import { Outlet, RouteObject } from "react-router";

import { routeConfig } from "utils/route-constants";
import { BookNowScreen, HomeScreen } from "screens/main";
import EntryScreen from "screens/main/entry";

export const mainRoutes: RouteObject = {
  path: "/",
  element: <Outlet />,
  children: [
    {
      path: "/",
      element: <EntryScreen />,
    },
    {
      path: "search",
      element: <HomeScreen />,
    },
    {
      path: routeConfig.bookNow.actual,
      element: <BookNowScreen />,
    },
  ],
};
