import { Outlet, RouteObject } from "react-router";

import { EditProfileScreen } from "screens/main";
import { LoginScreen } from "screens/auth";
import EntryScreen from "screens/main/entry";

const userToken = window.localStorage.getItem("token");

export const protectedRoutes: RouteObject = {
  path: "user",
  element: userToken ? <Outlet /> : <EntryScreen />,
  children: [
    {
      path: "profile",
      element: <EditProfileScreen />,
    },
  ],
};
