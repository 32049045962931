import { api } from "api";
import { AppForm, FieldError } from "components/common/app-form";
import { Field } from "formik";
import BaseLayout from "layouts/base";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { bookNowSchema, BookNowValidationRules } from "utils/validations";

const initValues: BookNowValidationRules = {
  name: "",
  email: "",
  message: "",
};

export default function BookNowScreen() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState<any>();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    api.get("/profile/" + id).then((res) => {
      setProfile(res.data.profile);
      setIsLoading(false);
    });
  }, []);

  const handleSubmit = async ({ formValues }: any) => {
    setSubmitLoading(true);

    try {
      await api
        .post("/booking", { ...formValues, toEmail: profile.email })
        .then((res) => {
          setSubmitLoading(false);
          navigate("/");
        });
    } catch (err) {
      setSubmitLoading(false);
    }
  };

  return (
    <BaseLayout>
      <h3 className="text-center">Book Now</h3>
      <hr />

      {isLoading && <div className="spinner-border" />}
      {!isLoading && (
        <div className="row mt-3 justify-content-center align-items-center">
          <div className="col col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <AppForm
              initialValues={initValues}
              validationSchema={bookNowSchema}
              handleSubmit={handleSubmit}
            >
              <div className="form-group mt-3">
                <label htmlFor="name">Name</label>
                <Field type="text" name="name" className="form-control" />
                <FieldError field="name" />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="contact_no">Contact Number</label>
                <Field
                  type="number"
                  name="contactNo"
                  className="form-control"
                />
                <FieldError field="contactNo" />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="email">Email</label>
                <Field type="text" name="email" className="form-control" />
                <FieldError field="email" />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="message">Message</label>
                <Field
                  as="textarea"
                  rows={5}
                  className="form-control"
                  name="message"
                />
                <FieldError field="message" />
              </div>
              {submitLoading && <div className="spinner-border" />}
              {!submitLoading && (
                <button type="submit" className="btn btn-success my-3">
                  Book Now
                </button>
              )}
            </AppForm>
          </div>
        </div>
      )}
    </BaseLayout>
  );
}
