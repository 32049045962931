import { api } from "api";
import { AppForm, FieldError } from "components/common/app-form";
import { getEvents } from "features/events/eventSlice";
import { Field } from "formik";
import BaseLayout from "layouts/base";
import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ProfileValidationRules, updateProfileSchema } from "utils/validations";

const initialValues: ProfileValidationRules = {
  gender: "Male",
  drivingSchoolName: "",
  contactNo: "",
  email: "",
  pricePerHour: 0,
  transmission: "Auto",
  serviceSuburbs: "",
  firstPackageHour: 0,
  firstPackagePrice: 0,
  secondPackageHour: 0,
  secondPackagePrice: 0,
  thirdPackageHour: "",
  thirdPackagePrice: "",
  drivingSchoolPic: "",
};

export default function EditProfileScreen() {
  const [profileImg, setProfileImg] = useState("");
  const [isLocalImg, setIsLocalImg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suburbsLoading, setSuburbsLoading] = useState(false);
  const [languagesLoading, setLanguagesLoading] = useState(false);
  const [suburbs, setSuburbs] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [singleSuburb, setSingleSuburb] = useState("");
  const [selectedSuburbs, setSelectedSuburbs] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [formLoading, setFormLoading] = useState(false);
  const [userFields, setUserFields] = useState<any>({});
  const [userEvents, setUserEvents] = useState<any>([]);

  const dispatch = useDispatch<any>()

  useEffect(() => {
    setIsLoading(true);
    setSuburbsLoading(true);
    setLanguagesLoading(true);

    api
      .get("/profile", {
        headers: { Authorization: window.localStorage.getItem("token") ?? "" },
      })
      .then((res) => {
        // console.log("res: ", res.data.user);
        setIsLoading(false);
        setUserFields(res.data.user);
        setSelectedSuburbs(res.data.user.suburbs ?? []);
        setSelectedLanguages(res.data.user.languages ?? [])

        api.get("/suburbs").then((res) => {
          const suburbs = res.data.suburbs.map((data: any) => data.locality);
          setSuburbs(suburbs);
          setSuburbsLoading(false);
        });

        // fetching languages data
        api.get("/languages").then((res) => {
          // storing only the name of the language
          const languages = res.data.languages.map((lang: any) => lang.name)
          setLanguages(languages)
          setLanguagesLoading(false)
        })
      });
  }, []);

  // fetch the events of the user to redirect to the booking page
  useEffect(() => {
    if(Object.keys(userFields).length > 0) {
      // console.log(userFields)
      api.get('/events', {
        headers: { Authorization: window.localStorage.getItem("token") ?? "" }
      }).then((res) => {
        // check if there are any events created or not
        if(res.data.events.length > 0) {
          setUserEvents(res.data.events.filter((event: any) => event.user === userFields._id)[0])
        }
      })
    }
  }, [userFields])

  // fetching the user events
  useEffect(() => {
    dispatch(getEvents())
  }, [])
  

  const handleChangeProfileImage = (e: any) => {
    setIsLocalImg(true);
    setProfileImg(e.target.files[0]);
  };

  const localImageURL = () => {
    if (profileImg) {
      return URL.createObjectURL(profileImg as any);
    }

    return "";
  };

  const onServiceSuburbsChanged = (selected: any) => {
    setSingleSuburb(selected[0]);
    // console.log("sububs selected: ", selected);
    setSelectedSuburbs(selected);
  };

  const onLanguagesChanged = (selected: any) => {
    // setSingleSuburb(selected[0]);
    // console.log("sububs selected: ", selected);
    setSelectedLanguages(selected);
  }

  const handleSubmit = async ({ formValues }: any) => {
    setFormLoading(true);
    // console.log("form_submitted: ", formValues);

    const _formValues = {
      ...formValues,
      serviceSuburbs: singleSuburb,
      image: profileImg,
      suburbs: selectedSuburbs,
      languages: selectedLanguages
    };

    // console.log("formvalues: ", _formValues);

    let fd = new FormData();
    for (const key in _formValues) {
      fd.append(key, _formValues[key]);
    }

    try {
      api
        .patch("/profile", fd, {
          headers: {
            Authorization: window.localStorage.getItem("token") ?? "",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setFormLoading(false);
        });
    } catch (err) {
      setFormLoading(false);
    }
  };

  return (
    <BaseLayout>
      <h3 className="text-center">Edit Profile</h3>
      <hr />
      <div className="row mt-3 justify-content-center align-items-center">
        <div className="col col-sm-6 col-md-6 col-lg-6 col-xl-6">
          {isLoading && <div className="spinner-border" />}
          {!isLoading && (
            <AppForm
              initialValues={userFields}
              validationSchema={updateProfileSchema}
              handleSubmit={handleSubmit}
              enableReinitialize
            >
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="image">Upload Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleChangeProfileImage}
                  />
                </div>
                <div className="col-md-6">
                  <img
                    src={
                      isLocalImg
                        ? localImageURL()
                        : userFields?.drivingSchoolPic ?? ""
                    }
                    alt=""
                    width={200}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="gender">Gender</label>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="gender"
                      value="Male"
                    />
                    Male
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="gender"
                      value="Female"
                    />
                    Female
                  </label>
                </div>
                <FieldError field="gender" />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="school_name">School Name</label>
                <Field
                  type="text"
                  name="drivingSchoolName"
                  className="form-control"
                />
                <FieldError field="drivingSchoolName" />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="school_name">Contact Number</label>
                <Field type="text" name="contactNo" className="form-control" />
                <FieldError field="contactNo" />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="school_name">Email</label>
                <Field
                  type="text"
                  name="email"
                  readOnly
                  className="form-control"
                />
                <FieldError field="email" />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="school_name">Price</label>
                <Field
                  type="number"
                  name="pricePerHour"
                  className="form-control"
                />
                <FieldError field="pricePerHour" />
              </div>
              <div className="mt-3">
                <label htmlFor="transmission">Transmission</label>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="transmission"
                      value="Auto"
                    />
                    Auto
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="transmission"
                      value="Manual"
                    />
                    Manual
                  </label>
                </div>
                <FieldError field="transmission" />
              </div>
              {suburbsLoading && <div className="spinner-border" />}
              {!suburbsLoading && (
                <div className="form-group mt-3">
                  <label htmlFor="suburbs">Service Suburbs</label>
                  <Typeahead
                    id="suburbs-update-profile"
                    options={suburbs}
                    defaultSelected={selectedSuburbs}
                    onChange={onServiceSuburbsChanged}
                    multiple
                  />
                  {selectedSuburbs.length === 0 && (
                    <FieldError
                      hasCustomMessage
                      message="Please select atleast one suburb"
                    />
                  )}
                </div>
              )}

              {languagesLoading && <div className="spinner-border" />}
              {!languagesLoading && (
                <div className="form-group mt-3">
                  <label htmlFor="languages">Languages Spoken</label>
                  <Typeahead
                    id="languages-update-profile"
                    options={languages}
                    defaultSelected={selectedLanguages}
                    onChange={onLanguagesChanged}
                    multiple
                  />
                  {selectedLanguages.length === 0 && (
                    <FieldError
                      hasCustomMessage
                      message="Please select atleast one language"
                    />
                  )}
                </div>
              )}

              <div className="mt-3">
                <h4 className="text-center">Packages</h4>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="package1_hour">How many Hours?</label>
                      <Field
                        type="number"
                        name="firstPackageHour"
                        className="form-control"
                      />
                      <FieldError field="firstPackageHour" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="package1_price">Price</label>
                      <Field
                        type="number"
                        name="firstPackagePrice"
                        className="form-control"
                      />
                      <FieldError field="firstPackagePrice" />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="package2_hour">How many Hours?</label>
                      <Field
                        type="number"
                        name="secondPackageHour"
                        className="form-control"
                      />
                      <FieldError field="secondPackageHour" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="package2_price">Price</label>
                      <Field
                        type="number"
                        name="secondPackagePrice"
                        className="form-control"
                      />
                      <FieldError field="secondPackagePrice" />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="package3_hour">Driving Test</label>
                      <Field
                        type="text"
                        name="testPackage"
                        className="form-control"
                      />
                      <FieldError field="testPackage" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="package3_price">Price</label>
                      <Field
                        type="text"
                        name="testPackagePrice"
                        className="form-control"
                      />
                      <FieldError field="testPackagePrice" />
                    </div>
                  </div>
                </div>
              </div>
              
              {formLoading && <div className="spinner-border" />}
              {!formLoading && (
                <button type="submit" className="btn btn-success my-3">
                  Save
                </button>
              )}
            </AppForm>
          )}
        </div>
      </div>
    </BaseLayout>
  );
}
